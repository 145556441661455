import React from 'react';

export const LogoCircleBlack = props => (
  <svg
    {...props}
    width="50"
    height="50"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M11.1 33c-.7 0-1.5-.4-1.8-1.1A21.6 21.6 0 0 1 38.4 2.6c1 .5 1.5 1.8 1 2.9-.6 1-2 1.4-3 .9A17.4 17.4 0 0 0 13 29.9c.6 1 .2 2.3-.9 2.9l-1 .2z"
        fill="#333"
      />
      <path
        d="M28.4 43.4c-3.5 0-7-.8-10.2-2.5-1-.6-1.4-1.9-.8-2.9.5-1 1.8-1.4 2.8-.9a17.3 17.3 0 0 0 23.4-23.5c-.5-1 0-2.3 1-2.9 1-.6 2.3-.2 2.8.9a21.6 21.6 0 0 1-19 31.8z"
        fill="#333"
      />
      <path
        d="M17.5 28.9c-.6 0-1.1-.3-1.4-.9A13.7 13.7 0 0 1 34.6 9.5c.8.4 1 1.3.7 2-.4.8-1.4 1-2.1.7a10.7 10.7 0 0 0-14.4 14.4c.4.8.1 1.7-.6 2-.2.2-.5.3-.7.3z"
        fill="#333"
      />
      <path
        d="M28.6 50A28.5 28.5 0 0 1 3.5 8 1.5 1.5 0 1 1 6 9.4 25.4 25.4 0 0 0 40.5 44c.7-.4 1.7-.1 2 .6.5.8.2 1.7-.6 2.1A28.6 28.6 0 0 1 28.6 50z"
        fill="#333"
      />
    </g>
  </svg>
);
